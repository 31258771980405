// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/Miedinger-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/fonts/Montserrat/Montserrat-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./assets/fonts/Montserrat/Montserrat-Bold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: \"Miedinger-Bold\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");\n}\n@font-face {\n  font-family: \"Montserrat\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\");\n}\n@font-face {\n  font-family: \"Montserrat-Bold\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\");\n}", "",{"version":3,"sources":["webpack://./src/Init.scss","webpack://./src/Global.scss"],"names":[],"mappings":"AAsBA;EACE,6BAAA;EACA,+DAAA;ACrBF;ADyBA;EACE,yBAAA;EACA,+DAAA;ACvBF;AD0BA;EACE,8BAAA;EACA,+DAAA;ACxBF","sourcesContent":["$points: (\n        xs: 0,\n        sm: 576px,\n        md: 768px,\n        lg: 992px,\n        xl: 1200px,\n        xxl: 1400px,\n        xxxl: 1600px,\n);\n\n@mixin media-up($breakpoint) {\n  @media (min-width: map-get($points, $breakpoint)) {\n    @content;\n  }\n}\n\n@mixin media-down($breakpoint) {\n  @media (max-width: map-get($points, $breakpoint) - 1px) {\n    @content;\n  }\n}\n\n@font-face {\n  font-family: 'Miedinger-Bold';\n  src: url('assets/fonts/Miedinger-Bold.ttf') format('truetype');\n}\n\n\n@font-face {\n  font-family: 'Montserrat';\n  src: url('assets/fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');\n}\n\n@font-face {\n  font-family: 'Montserrat-Bold';\n  src: url('assets/fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');\n}\n","@font-face {\n  font-family: \"Miedinger-Bold\";\n  src: url(\"assets/fonts/Miedinger-Bold.ttf\") format(\"truetype\");\n}\n@font-face {\n  font-family: \"Montserrat\";\n  src: url(\"assets/fonts/Montserrat/Montserrat-Regular.ttf\") format(\"truetype\");\n}\n@font-face {\n  font-family: \"Montserrat-Bold\";\n  src: url(\"assets/fonts/Montserrat/Montserrat-Bold.ttf\") format(\"truetype\");\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
